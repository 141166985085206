import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HomeBanner from "../../Components/Home/HomeBanner/HomeBanner";
import HomeIntro from "../../Components/Home/HomeIntro/HomeIntro";
import HomeGetStart from "../../Components/Home/HomeGetStart/HomeGetStart";
import HowCanWeHelp from "../../Components/Home/HowCanWeHelp/HowCanWeHelp";
import HomeTeam from "../../Components/Home/HomeTeam/HomeTeam";
import HomeBookConsultation from "../../Components/Home/HomeBookConsultation/HomeBookConsultation";
import BreadcrumbPage from "../../Components/Home/BreadcrumbPage/BreadcrumbPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@scss/bootstrap-overrides.scss";
import "@scss/global.scss";
import "@scss/grunticon.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
    
    return (
        <React.Fragment>
            <Header/>
            <HomeBanner/>
            <HomeIntro/>
            <HomeGetStart/>
            <HomeTeam/>
            <HomeBookConsultation/>
            <BreadcrumbPage/>
            <Footer/>
        </React.Fragment>
    )
}

export default Home