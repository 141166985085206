import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Container,  Row, Col } from "react-bootstrap";
import "./HomeGetStart.scss";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import bgImg from "@images/bg.jpg";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import { CONTACT_PAGE_URL } from "@Components/common/site/constants";

import { inViewOptions } from '../../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const HomeIntro = (props) => {
    const data = useStaticQuery(graphql`
    query{
    glstrapi {
    helpContactBlock {
        imagetransforms
        ggfx_results {
            id
            content_type
            transforms
            src_import_url
            src_cftle
            field
          }
        id
        Sub_Title
        Content
        Email
        Telephone
        Title
        Video_Call
        WhatsApp
        Image {
          url
          alternativeText
        }
      }
    }
    }
    `);
    let processedImages = JSON.stringify({});
    // if (data.glstrapi?.helpContactBlock?.imagetransforms?.Image_Transforms) {
    //     processedImages = data.glstrapi?.helpContactBlock?.imagetransforms.Image_Transforms;
    // }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => {
                return (
                    <motion.section
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <React.Fragment>
                            {props.nobanner ?
                                <section className="get-started padding-fix" style={{ backgroundImage: `url("${bgImg}")` }}>
                                    <Container>
                                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                            <Row>
                                                <ScrollAnimation animateOnce={true} animateIn='fadeIn' duration={2}>
                                                    <Col lg={12}>
                                                        <h2>{props.Title ? props.Title : data.glstrapi?.helpContactBlock?.Title} {props.nobanner ? null : <span className="stroke-text">{data.glstrapi?.helpContactBlock?.Sub_Title}</span>}</h2>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <ReactMarkdown source={props.Content ? props.Content : data.glstrapi?.helpContactBlock?.Content} allowDangerousHtml />
                                                    </Col>
                                                </ScrollAnimation>
                                                <Col lg={12}>
                                                    <ul>

                                                        <li>
                                                            <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link href={"tel:" + data.glstrapi?.helpContactBlock?.Telephone}><i className="icon-phone"></i><span>Telephone</span></Link>
                                                            </ScrollAnimation>
                                                        </li>
                                                        <li>
                                                            <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link to={CONTACT_PAGE_URL.alias}><i className="icon-envelope"></i><span>Email</span></Link>
                                                            </ScrollAnimation>
                                                        </li>
                                                        <li>
                                                            <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link to={data.glstrapi?.helpContactBlock?.Video_Call}><i className="icon-video-call"></i><span>Video Call</span></Link>
                                                            </ScrollAnimation>
                                                        </li>
                                                        <li>
                                                            <ScrollAnimation animateOnce={true} animateIn='flipInX' duration={2}><Link target="_blank" href={"https://wa.me/" + data.glstrapi?.helpContactBlock?.WhatsApp}><i className="icon-whatsapp"></i><span>WhatsApp</span></Link>
                                                            </ScrollAnimation>
                                                        </li>

                                                    </ul>
                                                </Col>

                                            </Row>
                                        </ScrollAnimation>
                                    </Container>
                                </section>
                                :
                                <div  className='get-started image'>
                                    <Container>
                                        <Row><ScrollAnimation animateOnce={true} animateIn='fadeIn' duration={2}>
                                            <Col lg={12}>
                                                <h2>{props.Title ? props.Title : data.glstrapi?.helpContactBlock?.Title} {props.nobanner ? null : <span className="stroke-text">{data.glstrapi?.helpContactBlock?.Sub_Title}</span>}</h2>
                                            </Col>
                                            <Col lg={12}>
                                                <ReactMarkdown source={props.Content ? props.Content : data.glstrapi?.helpContactBlock?.Content} allowDangerousHtml />
                                            </Col>
                                        </ScrollAnimation>
                                            <Col lg={12}>
                                                <ul>

                                                    <li>
                                                        <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link href={"tel:" + data.glstrapi?.helpContactBlock?.Telephone}><i className="icon-phone"></i><span>Telephone</span></Link>
                                                        </ScrollAnimation>
                                                    </li>
                                                    <li>
                                                        <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link to={CONTACT_PAGE_URL.alias}><i className="icon-envelope"></i><span>Email</span></Link>
                                                        </ScrollAnimation>
                                                    </li>
                                                    <li>
                                                        <ScrollAnimation animateOnce={true} animateIn='flipInX'><Link to={data.glstrapi?.helpContactBlock?.Video_Call}><i className="icon-video-call"></i><span>Video Call</span></Link>
                                                        </ScrollAnimation>
                                                    </li>
                                                    <li>
                                                        <ScrollAnimation animateOnce={true} animateIn='flipInX' duration={2}><Link target="_blank" href={"https://wa.me/" + data.glstrapi?.helpContactBlock?.WhatsApp}><i className="icon-whatsapp"></i><span>WhatsApp</span></Link>
                                                        </ScrollAnimation>
                                                    </li>

                                                </ul>
                                            </Col>

                                        </Row>
                                    </Container>
                                </div>
                            }
                        </React.Fragment>
                    </motion.section>
                )
            }}
        </InView>
    )
}

export default HomeIntro;